import React from 'react';

function Loading() {
  return (
    // <div className="box">
    //     <div className="loader1"></div>
    // </div>
    <div className="loading">
      <div className="loading-sun"></div>
    </div>
  );
}

export default Loading;
